import { MinusCircleIcon, PencilIcon, PlusCircleIcon } from "@heroicons/react/24/solid"
import { NodeKey } from "lexical"
import { ContentSummary, GridSlot, GridSlotSize } from "common/types/graphql"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { PropsWithChildren, useCallback, useEffect, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { ImmutableGridSlotProps, ImmutableGridSlot } from "./ImmutableGridSlot"

export interface MutableGridSlotProps extends Omit<ImmutableGridSlotProps, "isOver"> {
  /** The index of the grid slot. */
  index: number

  gridNodeKey?: NodeKey

  /**
   * A handler for the clear action.
   *
   * @param index The index of the grid slot.
   */
  onClear?: (index: number) => void

  /**
   * A handler for the drop action.
   *
   * @param index The index of the grid slot.
   * @param item The dropped item.
   */
  onDrop?: (index: number, item: DragItem) => void

  /**
   * A handler for swapping slots.
   *
   * @param a The index of the first slot.
   * @param b The index of the second slot.
   */
  onSwap?: (a: number, b: number, item: DragItem) => void

  /**
   * A handler for the edit action.
   *
   * @param index The index of the grid slot.
   */
  onEdit?: (index: number) => void
  /**
   * A handler for the add action.
   *
   * @param index The index of the grid slot.
   */
  onAdd?: (index: number) => void
}

interface DragItem extends Partial<ContentSummary> {
  index: number
  swappable: boolean
  sourceGridNodeKey?: NodeKey
}

/** A grid slot that can be modified by a user */
export const MutableGridSlot = ({
  index,
  gridNodeKey,
  size = GridSlotSize.Base,
  type = GridSlot.Editorial,
  asPlaceholder = false,
  onClear,
  onDrop,
  onSwap,
  onEdit,
  onAdd,
  children,
}: PropsWithChildren<MutableGridSlotProps>) => {
  const t = useTranslations("blocks.grid")

  const onClearButtonClick = useCallback(() => {
    onClear && onClear(index)
  }, [onClear, index])

  const onEditButtonClick = useCallback(() => {
    onEdit && onEdit(index)
  }, [onEdit, index])

  const onAddButtonClick = useCallback(() => {
    onAdd && onAdd(index)
  }, [onAdd, index])

  const [{}, drag] = useDrag(
    () => ({
      type,
      item: { index, swappable: true, sourceGridNodeKey: gridNodeKey },
      canDrag: !asPlaceholder,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [asPlaceholder, index],
  )

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: type,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      drop: (item: DragItem) => {
        /*
          Swaps source and target items if they exist in the same grid.
        */
        if (item.sourceGridNodeKey === gridNodeKey && item.index !== index) {
          onSwap && onSwap(item.index, index, item)
        }

        /*
          Drop an item if it's dragged from search results or another grid block.
        */
        if (item.sourceGridNodeKey !== gridNodeKey) {
          onDrop && onDrop(index, item)
        }
      },
    }),
    [onDrop, onSwap],
  )

  return (
    <ImmutableGridSlot
      size={size}
      type={type}
      asPlaceholder={asPlaceholder}
      isOver={isOver}
      // @ts-ignore
      ref={(node) => drag(drop(node))}
    >
      {children}
      <div className="absolute right-0 hidden border-collapse shadow-md group-hover:flex">
        {onEdit && (
          <button
            onClick={onEditButtonClick}
            aria-label={t("customise_edit")}
            className="border border-r-0 border-gray-300 bg-white p-2 "
          >
            {t("edit")} <PencilIcon className="inline-block h-4 w-4" />
          </button>
        )}
        {onAdd && (
          <button
            onClick={onAddButtonClick}
            aria-label={t("add_custom_content")}
            className="border border-r-0 border-gray-300 bg-white p-2"
          >
            {t("add_custom_content")} <PlusCircleIcon className="inline-block h-6 w-6" />
          </button>
        )}
        {onClear && (
          <button
            onClick={onClearButtonClick}
            aria-label={t("clear_slot")}
            className="border border-gray-300 bg-white p-2"
          >
            <MinusCircleIcon className="h-6 w-6 text-red-500" />
          </button>
        )}
      </div>
    </ImmutableGridSlot>
  )
}
